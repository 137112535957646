import React, { useState } from "react"
import { Card, Collapsible, FormLayout, TextField } from "@shopify/polaris"
import update from "immutability-helper"
export default function CornerRadiusForm({
  ButtonWrapper,
  dropdownButtonMarkup,
  state,
  setState,
}) {
  const [open, setOpen] = useState(true)
  const handleToggle = () => {
    setOpen(!open)
  }
  console.log("state is: ", state)
  return (
    <Card>
      <Card.Section>
        <p>Forms</p>
      </Card.Section>
      <Card.Section>
        <ButtonWrapper
          onClick={(e) => {
            e.preventDefault()
            handleToggle()
          }}
          ariaExpanded={open}
          ariaControls={`corner-radius-collapsible`}
        >
          {dropdownButtonMarkup("Corner radius", "", open)}
        </ButtonWrapper>
      </Card.Section>
      {open && (
        <Card.Section>
          <Collapsible
            open={open}
            id={`corner-radius-collapsible`}
            transition={{
              duration: "500ms",
              timingFunction: "ease-in-out",
            }}
            expandOnPrint
          >
            <Card sectioned subdued>
              <FormLayout>
                <TextField
                  value={state.formCornerRadiusBase}
                  label="Base"
                  type="number"
                  suffix="px"
                  placeholder="5"
                  onChange={(value) => {
                    setState(
                      update(state, { formCornerRadiusBase: { $set: value } })
                    )
                  }}
                />
                <TextField
                  value={state.formCornerRadiusLarge}
                  label="Large"
                  type="number"
                  suffix="px"
                  placeholder="10"
                  onChange={(value) => {
                    setState(
                      update(state, { formCornerRadiusLarge: { $set: value } })
                    )
                  }}
                />
                <TextField
                  value={state.formCornerRadiusSmall}
                  label="Small"
                  type="number"
                  suffix="px"
                  placeholder="2"
                  onChange={(value) => {
                    setState(
                      update(state, { formCornerRadiusSmall: { $set: value } })
                    )
                  }}
                />
              </FormLayout>
            </Card>
          </Collapsible>
        </Card.Section>
      )}
    </Card>
  )
}
